@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.page-content {
  max-height: 100vh;
  overflow: auto;
}

.name {
  letter-spacing: 30px;
  filter: drop-shadow(0px 6px 10px #000000);
  color: #567b87;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 100;
}

.pageTitle {
  color: rgba(255, 255, 255, 0.1);
}

.subtitle {
  color: rgba(255, 255, 255, 0.5);
}

.heading {
  /* background-color: rgba(0, 0, 0, 0.143); */
  width: 100%;
  margin-bottom: 10px;
}

.languages {
  color: rgba(255, 255, 255, 0.5);
  /* letter-spacing: 0.8vw; */
  /* font-size: 1vw; */
}

.project {
  color: #3d5257;
  /* letter-spacing: 0.7vw; */
}
.react-player {
  width: 300px !important;
  height: 300px !important;
}

/* For medium screens */
@media (min-width: 769px) {
  .react-player {
    width: 500px !important;
    height: 500px !important;
  }
}

/* For large screens */
@media (min-width: 1025px) {
  .react-player {
    width: 900px !important;
    height: 550px !important;
  }
}
