.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.footerr {
  display: flex;
  justify-content: center;
  height: 35px;
  align-items: center;
}
.footer-container {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 50%;
}
.header-container {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  z-index: 10;
}
@media (max-width: 500px) {
  .footer-container {
    width: 80%;
  }
  .header-container {
    display: block !important;
  }
}

.footer-link,
a {
  text-decoration: none;
  color: rgb(164, 164, 164);
  font-weight: bold;
}
.footer-link:visited {
  color: rgb(164, 164, 164);
}
.footer-link:hover,
a {
  color: rgb(124, 124, 124);
}

.header-link,
a {
  text-decoration: none;
  color: rgb(195, 195, 195);
}
.header-link:visited {
  color: rgb(195, 195, 195);
}

.header-link {
  padding: 10px;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .header-link {
    width: 80px !important;
    padding: 0px;
    font-size: small !important;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
