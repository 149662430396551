.centered-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  /* padding-bottom: 150px; */
}

.centered-image-title {
  margin-bottom: 1rem;
  color: white;
}

.centered-image {
  max-width: 70%;
  max-height: 70%;
  object-fit: cover;
}
@media (min-width: 2024px) {
  .centered-image {
    max-width: 50%;
    max-height: 50%;
  }
}
@media (max-width: 1200px) {
  .centered-image {
    max-width: 90%;
    max-height: 90%;
  }
  .centered-image-text {
    max-width: 90% !important;
  }
  .centered-image-container {
    padding-top: 0px !important;
  }
}

.centered-image-text {
  margin-top: 1rem;
  color: white;
  max-width: 60%;
}
