@import url("https://fonts.googleapis.com/css2?family=Pathway+Extreme:wght@100&display=swap");

.centered-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.heading {
  margin: 0px !important;
  /* font-family: "Pathway Extreme", sans-serif; */
}

@media (max-width: 500px) {
  .heading {
    font-size: 70px !important;
  }
}
