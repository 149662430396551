/* @media (max-width: 1200px) {
  .pageTitle {
    margin-top: 100px;
    font-size: 50px !important;
  }
}
@media (max-width: 1200px) {
  .pageTitle h1 {
    font-size: 80px !important;
  }
} */

.pageTitle h1 {
  font-family: "Abel", sans-serif;
}
