.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icons a {
  color: #ffffff;
  font-size: 2rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0077b5;
}
